import { VALIDATE_ZIPCODES } from 'features/targetingV2/graphql/location/queries';
import {
  IOptionType,
  IParameterValueOption,
  NodeLike,
  SectionType,
} from 'features/targetingV2/types/common';
import {
  IParameter,
  IParameterInfo,
} from 'features/targetingV2/types/targeting';
import {
  defaultParameter,
  getDefaultEnabledGrades,
} from 'features/targetingV2/utils/defaults';
import {
  Clusivity,
  InventoryParameterType,
  LocationParameterType,
  TechnologyParameterType,
} from 'interfaces/generated.types';
import { isEqual } from 'lodash';
import { MutableRefObject } from 'react';
import { getSelectOptions } from 'utils/dataTransformation';

export const daxFAQsUrl =
  'https://thisisglobal.sharepoint.com/sites/DAXFrequentlyAskedQuestions';

export const daxFAQsSitePagesUrl = [daxFAQsUrl, 'SitePages'].join('/');

export const mandatoryParameters: string[] = [LocationParameterType.Geo];

export const isTargetingParameterInvalid = (
  parameter: {
    type?: any;
    clusivity?: Clusivity;
    values: any[];
  },
  parametersToIgnoreValidationFor?: string[]
) =>
  (!parameter.type || !parameter.clusivity || parameter.values.length === 0) &&
  !parametersToIgnoreValidationFor?.includes(parameter.type);

export const hasInvalidParameters = (parameters: IParameter[]) =>
  parameters.some((parameter) => isTargetingParameterInvalid(parameter));

export const isParameterMandatory = (
  parameterType: string,
  parameters: IParameter[]
) =>
  mandatoryParameters.includes(parameterType) &&
  parameters.filter((parameter) => mandatoryParameters.includes(parameter.type))
    .length === 1;

export const sortItemsAlphabetically = (
  items: any[],
  fieldForSorting: string
) =>
  items.sort((a: { [x: string]: string }, b: { [x: string]: string }) => {
    if (fieldForSorting && a[fieldForSorting] && b[fieldForSorting]) {
      return a[fieldForSorting].toLowerCase().localeCompare(b[fieldForSorting]);
    }

    return -1;
  });

export const sortStringsAlphabetically = (values: string[]) => {
  const copiedValues = [...values];
  return copiedValues.sort((a, b) => a.toLowerCase().localeCompare(b));
};

export const getSectionTitle = (section: SectionType) => {
  switch (section) {
    case SectionType.AdRequestEnrichmentAudience:
    case SectionType.Audience:
      return 'Audience';
    case SectionType.Location:
      return 'Location';
    case SectionType.Custom:
      return 'Custom';
    case SectionType.Inventory:
      return 'Inventory';
    case SectionType.Technology:
      return 'Technology';
    case SectionType.DayTime:
      return 'Day & Time';
    default:
      return 'Section';
  }
};

export const parametersWithBreadCrumbsOnMultiValue: string[] = [
  LocationParameterType.Geo,
  InventoryParameterType.Supply,
];

export const sortArrayBasedOnOrder = (array: any[], order: any[]) => {
  array.sort((a, b) => order.indexOf(a) - order.indexOf(b));
};

export const getNoOfMultiValuesToShow = (parameterType: string) => {
  switch (parameterType) {
    case LocationParameterType.Zipcode:
      return 5;
    case LocationParameterType.LatLong:
    case TechnologyParameterType.Isp:
      return 2;
    default:
      return undefined;
  }
};

export const handleNoOptionsText = (parameterType: string) => {
  switch (parameterType) {
    case LocationParameterType.LatLong:
      return null;
    default:
      return 'No options';
  }
};

export const parametersWithBulkUpload: string[] = [
  LocationParameterType.Zipcode,
  LocationParameterType.LatLong,
];

export const hasBulkUpload = (parameterType: string) =>
  parametersWithBulkUpload.includes(parameterType);

export const getParameterLabel = (
  parameterTypeOptions: IOptionType[],
  parameter: IParameter
) =>
  parameterTypeOptions.find(
    (parameterTypeOption) => parameter.type === parameterTypeOption.value
  )?.label;

export const convertArrayToObject = (
  array: { [key: string]: any }[],
  keyFieldName: string
): { [key: string]: any } =>
  array.reduce(
    (arrayItem, currentItem) => ({
      ...arrayItem,
      [currentItem[keyFieldName]]: currentItem,
    }),
    {}
  );

export const getParameterValuesDropdownPlaceholder = (
  parameterType: string,
  isLocationLimited?: boolean,
  limitLocationCountryLabel = ''
) => {
  switch (parameterType) {
    case LocationParameterType.Geo:
      return `All of ${
        isLocationLimited ? limitLocationCountryLabel : 'the world'
      }, or find geo location by name`;
    case LocationParameterType.Zipcode:
      return 'Search for postcodes or upload a CSV';
    case TechnologyParameterType.Isp:
      return 'Search ISPs';
    default:
      return '';
  }
};

export const resetFileInputValue = (
  externalRef: MutableRefObject<HTMLInputElement | null>
) => {
  const { current } = externalRef;
  if (current) {
    current.value = '';
  }
};

export const handleFileUploadNativeClick =
  (externalRef: MutableRefObject<HTMLInputElement | null>) => () => {
    resetFileInputValue(externalRef);
    const { current } = externalRef;
    if (current) {
      current.click();
    }
  };

export const getToggledNode = (
  nodesList: NodeLike[],
  otherNodesList: NodeLike[]
) => {
  const nodesListObject = convertArrayToObject(nodesList, 'value');

  return otherNodesList.find((node) => !nodesListObject[node.value]);
};

export const removeNode = (selectedNodes: NodeLike[], node: NodeLike) =>
  selectedNodes.filter((selectedNode) => selectedNode.value !== node.value);

export const haveValuesChanged = (values: IParameterValueOption[]) =>
  values.length > 0;
export const hasClusivityChanged = (clusivity: Clusivity | undefined) => {
  if (!clusivity) {
    return false;
  }
  return clusivity !== defaultParameter.clusivity;
};

export const hasPairedParameterChanged = (
  parameter: IParameter,
  pairedParametersLength: number
) => {
  const changedClusivity =
    pairedParametersLength === 1 && hasClusivityChanged(parameter.clusivity);

  return haveValuesChanged(parameter.values) || changedClusivity;
};

export const hasParameterChanged = (parameter: any) => {
  const haveGradesChanged =
    parameter.enabledGrades &&
    !isEqual(parameter.enabledGrades, getDefaultEnabledGrades(parameter.type));

  return (
    haveValuesChanged(parameter.values) ||
    hasClusivityChanged(parameter.clusivity) ||
    !!haveGradesChanged
  );
};

export const getNewGroupWithFirstParameter = (parameterType: string) => ({
  parameters: [
    {
      ...defaultParameter,
      type: parameterType,
      isFocused: true,
    },
  ],
});

export const getGroupCreationOptionDetails = (
  allParametersInfo: IParameterInfo[],
  sectionTitle: string
) => {
  const availableOptions = getSelectOptions(allParametersInfo, 'name', 'type');

  return {
    caption: {
      text: `Learn about ${sectionTitle} Groups in our `,
      link: {
        label: 'FAQ',
        url: `${daxFAQsSitePagesUrl}/When-%26-how-to-create-multiple-audiences.aspx`,
      },
    },
    options: availableOptions,
  };
};

export const getQueryBasedOnType = (parameterType: string): any => {
  switch (parameterType) {
    case LocationParameterType.Zipcode:
      return VALIDATE_ZIPCODES;
    default:
      return '';
  }
};
